<template>
  <div>
    <b-sidebar
      lazy
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <sidebar-template title="Question Details" :closeAction="closeSidebar">
        <template #content>
          <response-form-card
            v-if="selectedResponse !== null"
            :reloadParent="load"
            :response="selectedResponse"
          />
        </template>
      </sidebar-template>
    </b-sidebar>

    <b-sidebar
      id="sidebar-question-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="620px"
    >
      <sidebar-template title="Question Details" :closeAction="closeDetailsSidebar">
        <template #content>
          <response-question-details-view
            :response="selectedResponse"
            :reloadParent="load"
            :assessmentStatus="currentQuestionnaire.assessment.status"
          />
        </template>
      </sidebar-template>
    </b-sidebar>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="w-100 card" v-if="currentQuestionnaire !== null">
        <div class="card-body d-flex flex-row justify-content-center align-items-start">
          <div
            class="d-flex flex-column align-items-start justify-content-start"
            style="width: 60%"
          >
            <h3 class="font-weight-bold">{{ currentQuestionnaire.title }}</h3>
            <p>{{ currentQuestionnaire.description }}</p>
          </div>
          <div
            class="d-flex flex-row align-items-center justify-content-around"
            style="width: 39%"
          >
            <div class="d-flex flex-column align-items-center justify-content-around">
              <h4 class="font-weight-bolder">Gap</h4>
              <donut-bar
                :percentage="Number(currentQuestionnaire.gap.toFixed(2))"
                :displayValue="currentQuestionnaire.gap + '%'"
                size="sm"
                :uniqueId="currentQuestionnaire._id + 1"
              />
            </div>

            <div class="d-flex flex-column align-items-center justify-content-around">
              <h4 class="font-weight-bolder">Progress</h4>
              <donut-bar
                :percentage="Number(currentQuestionnaire.completion.toFixed(2))"
                :displayValue="currentQuestionnaire.completion + '%'"
                size="sm"
                :uniqueId="currentQuestionnaire._id + 2"
                :colorsReversed="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div
          class="card-header d-flex flex-column align-items-start justify-content-start"
        >
          <h2 class="text-turncate mb-50">Responses</h2>
          <p v-if="isUserVendor == true" class="mb-0">
            Click on any question to start the assessment.
          </p>
          <p v-else class="mb-0">All responses of this assessment are listed below.</p>
        </div>
        <div class="pb-25 pt-0 w-100 row">
          <div class="app-fixed-search col-sm-6">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  v-if="filters.search == null || filters.search == ''"
                  icon="SearchIcon"
                  class="text-muted"
                />
                <feather-icon
                  @click="
                    () => {
                      filters.search = null;
                    }
                  "
                  v-else
                  icon="XIcon"
                  class="text-danger cursor-pointer"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="filters.search"
                placeholder="Search..."
                debounce="500"
                size="md"
              />
            </b-input-group>
          </div>
          <div class="col-sm-6">
            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-form-select
                style="width: 300px"
                v-model="filters.status"
                :options="complianceOptions"
                placeholder="Filter Compliance"
              ></b-form-select>
            </div>
          </div>
        </div>
        <table role="table" class="table table-hover">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">#</th>
              <!-- <th role="columnheader" scope="col">Ref.no</th> -->
              <!-- <th role="columnheader" scope="col">Question</th> -->
              <th role="columnheader" scope="col">Question</th>
              <th role="columnheader" scope="col" class="text-center">Response</th>
              <th role="columnheader" scope="col" class="text-center">Compliance</th>
              <!-- <th role="columnheader" scope="col" class="text-center">Actions</th> -->
            </tr>
          </thead>
          <tbody role="rowgroup">
            <template v-if="responses && responses != null && responses.length > 0">
              <tr
                v-for="(question, i) in responses"
                :key="question._id"
                role="row"
                class="cursor-pointer"
                @click="handleDetailsViewClick(question)"
              >
                <td role="cell">
                  <div class="d-flex justify-content-start">
                    <p class="text-left font-weight-bold mb-0">
                      {{ i + pagination.from }}
                    </p>
                  </div>
                </td>
                <!-- <td>{{ question.question_number }}</td> -->
                <td role="cell" style="max-width: 25vw">
                  <div class="w-100">
                    <p class="text-left font-weight-bold mb-25">
                      {{ question.title }}
                    </p>
                    <small class="text-left mb-0">
                      {{ question.description }}
                    </small>
                  </div>
                </td>

                <td role="cell">
                  <div class="d-flex align-items-center justify-content-center w-100">
                    <b-badge
                      :variant="getResponseVariant(question.response)"
                      v-if="question.response && question.response != null"
                      >{{ getResponseValue(question.response) }}</b-badge
                    >
                  </div>
                </td>

                <td>
                  <div class="d-flex align-items-center justify-content-center w-100">
                    <b-badge
                      :variant="getResponseVariant(question.status)"
                      v-if="question.status && question.status != null"
                      >{{ getComplianceValue(question.status) }}</b-badge
                    >
                  </div>
                </td>
                <!-- <td role="cell">
                <div
                  class="d-flex align-items-center justify-content-between w-100"
                ></div>
              </td> -->
              </tr>
            </template>
            <template v-else>
              <empty-table-section title="Responses Empty">
                <template #content>
                  <p class="text-center">
                    It seems there are no responses at the moment.
                  </p>
                </template>
              </empty-table-section>
            </template>
          </tbody>
        </table>
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-center w-100">
            <b-pagination
              v-model="filters.page"
              :per-page="pagination.perPage"
              :total-rows="pagination.total"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BButton,
  BListGroup,
  BListGroupItem,
  BSidebar,
  BCard,
  BBadge,
  BOverlay,
  BFormSelect,
  VBTooltip,
  BCol,
  BFormTextarea,
  BProgress,
  VBHover,
  BAvatar,
  BPagination,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import { VBToggle } from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import DonutBar from "../../../components/DonutBar.vue";
import ResponseFormCard from "./components/ResponseFormCard.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import ThirdPartyRisksRAMixins from "../../../mixins/ThirdPartyRisksRAMixins";
import SidebarTemplate from "../../../components/SidebarTemplate.vue";
import ResponseQuestionDetailsView from "./components/ResponseQuestionDetailsView.vue";
import EmptyTableSection from "../../../components/EmptyTableSection.vue";

export default {
  name: "QuestionnaireQuestionAssessment",
  components: {
    BForm,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    draggable,
    BSidebar,
    BCard,
    VBToggle,
    BBadge,
    BOverlay,
    BFormSelect,
    VueAutosuggest,
    VBTooltip,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BPagination,
    BFormTextarea,
    BProgress,
    DonutBar,
    VBHover,
    BAvatar,
    ResponseFormCard,
    SidebarTemplate,
    ResponseQuestionDetailsView,
    EmptyTableSection,
    DonutBar,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    "b-hover": VBHover,
  },
  data() {
    return {
      currentQuestionnaire: null,
      responses: null,
      showOverlay: false,
      selectedResponse: null,
      activeResponseId: null,
      currentResponseIndex: 0,
      isUserVendor: false,
      filters: {
        page: 1,
        search: "",
        status: null,
      },
      pagination: {
        perPage: 10,
        lastPage: 1,
        total: 0,
        from: 0,
      },

      complianceOptions: [
        { text: "--Filter By Compliance--", value: null },
        { text: "Yes", value: 1 },
        { text: "No", value: 2 },
        { text: "Not Applicable", value: 3 },
      ],
    };
  },

  mixins: [ResponseMixins, QuestionnaireMixins, ThirdPartyRisksRAMixins],

  mounted() {
    this.load();
  },

  watch: {
    filters: {
      handler: function (newValue) {
        // this.getQuestionnaireResponsesAndSetData(
        //   this.$route.params.assessmentId,
        //   this.$route.params.questionnaireId
        // );

        this.getAssessmentQuestionnaireResponsesAndSetData(
          newValue,
          this.$route.params.assessmentId,
          this.$route.params.questionnaireId
        );
      },
      deep: true,
    },
    currentResponseIndex(newValue) {
      const newSelectedResponse = this.responses[newValue];
      this.setSelectedResponseData(newSelectedResponse);
    },
  },

  methods: {
    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }
      this.getAssessmentQuestionnaireDetailsAndSetData(
        this.$route.params.assessmentId,
        this.$route.params.questionnaireId
      );
      this.getAssessmentQuestionnaireResponsesAndSetData(
        this.filters,
        this.$route.params.assessmentId,
        this.$route.params.questionnaireId
      );
    },

    handleDetailsViewClick(response) {
      this.selectedResponse = response;
      this.openDetailsSidebar();
    },

    closeDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-question-details");
    },
    openDetailsSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-question-details");
      });
    },

    getAssessmentQuestionnaireDetailsAndSetData(assessmentId, questionnaireId) {
      this.showOverlay = true;
      this.getAssessmentQuestionnaires(assessmentId, questionnaireId)
        .then((res) => {
          this.currentQuestionnaire = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getAssessmentQuestionnaireResponsesAndSetData(params, assessmentId, questionnaireId) {
      this.showOverlay = true;
      this.getAssessmentQuestionnaireResponses(params, assessmentId, questionnaireId)
        .then((res) => {
          this.responses = res.data.data.data;
          if (this.responses.length > 0) {
            this.setSelectedResponseData(res.data.data.data[0]);
          }

          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.total = res.data.data.total;
          this.pagination.from = res.data.data.from;
          this.filters.page = res.data.data.current_page;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleQuestionClick(question) {
      this.setSelectedResponseData(question);
      this.openSidebar();
    },

    getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Empty";
        }
      }
    },

    getComplianceValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Empty";
        }
      }
      return "Empty";
    },

    getResponseVariant(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "success";
          case 2:
            return "danger";
          case 3:
            return "light-dark";
          default:
            return "light-dark";
        }
      }
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },

    getStatusNumber(num) {
      switch (num) {
        case 1:
          return "In Progress";
        case 2:
          return "Completed";
        default:
          return "Unknown";
      }
    },
    getStatusColor(num) {
      switch (num) {
        case 1:
          return "info";
        case 2:
          return "success";
        default:
          return "info";
      }
    },

    handleNextClick() {
      const currentIndex = this.responses.findIndex(
        (res) => res._id == this.activeResponseId
      );
      if (currentIndex < this.responses.length) {
        const nextResponse = this.responses[currentIndex + 1];
        this.setSelectedResponseData(nextResponse);
      }
    },
    handlePreviousClick() {
      const currentIndex = this.responses.findIndex(
        (res) => res._id == this.activeResponseId
      );
      if (currentIndex > 0) {
        const nextResponse = this.responses[currentIndex - 1];
        this.setSelectedResponseData(nextResponse);
      }
    },

    handleResponseClick(response) {
      this.setSelectedResponseData(response);
    },

    setSelectedResponseData(response) {
      this.selectedResponse = response;
      this.activeResponseId = response._id;
      this.currentResponseIndex = this.responses.findIndex(
        (res) => res._id == response._id
      );
    },

    // getResponseValue(num) {
    //   if (num !== null) {
    //     let newNum = Number(num);
    //     switch (newNum) {
    //       case 1:
    //         return "Yes";
    //       case 2:
    //         return "No";
    //       case 3:
    //         return "Not Applicable";
    //       default:
    //         return "Not Applicable";
    //     }
    //   }
    // },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>

<style lang="scss" scoped>
.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.icon-container {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 3px;
}

.sidebar-left {
  width: 25%;

  .todo-sidebar {
    width: 100%;
  }
}

.todo-bottom-section {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
}
</style>

<style lang="scss" scoped>
.bottom-area {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 200px;
}

.list-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 100px;
  transition: all 0.4s ease-in-out;

  &:hover {
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    //   rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }

  .header-section {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }
  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>
